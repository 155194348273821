import React, { FC, useContext, useEffect, useState } from 'react'
import { Layout } from '@sistemiv/s-components'
import DashboardSwitch from '../components/dashboard/DashboardSwitch'
import TopNav from '../components/nav/TopNav'
import SideNav from '../components/nav/SideNav'
import { createSignalRContext } from 'react-signalr'
import { useMsal } from '@azure/msal-react'
import { useParams } from 'react-router-dom'
import { getBaseUrl } from '../services/http-common'
import { OrganizationsContext } from '../context/OrganizationsContext'
import UserService from '../services/UserService'
import { ApplicationsContext } from '../context/ApplicationsContext'
import ProfileService from '../services/Profile.service'
// import ErrorHandlingInterceptor from '../components/error-handling/ErrorHandlingInterceptor'

export const SignalRContext = createSignalRContext()

export const refreshUsersEvents = [
  'MembershipDeactivatedEvent',
  'MembershipActivatedEvent',
  'MembershipDeletedEvent',
  'UserInvitedEvent',
  'InvitationAcceptedEvent',
  'InvitationExpiredEvent',
  'MembershipRejectedEvent',
  'MembershipSetToInvitationSentEvent',
  'UserInvitedEvent',
  'UserApprovedEvent',
  'UserJoinRequestedEvent',
]

export const groupsEvents = ['UserAddedToGroupEvent', 'UserGroupDeletedEvent', 'UserRemovedFromGroupEvent']
export const nodesEvents = ['OrganizationNodeDeletedEvent', 'UserAddedToOrganizationNodeEvent']

const Dashboard: FC = () => {
  const { instance } = useMsal()
  const { org } = useParams()
  const [token, setToken] = useState('')
  const loggedInUserId = instance.getActiveAccount()?.localAccountId
  const { setOrganizations } = useContext(OrganizationsContext)
  const { setApplications, setProfileApps } = useContext(ApplicationsContext)

  useEffect(() => {
    const account = instance.getActiveAccount()
    if (account) {
      const accessTokenRequest = {
        scopes: ['fd1442d1-cbcc-4b73-a893-a61b8d3d4782'],
        account: account,
      }
      instance.acquireTokenSilent(accessTokenRequest).then((response) => setToken(response.accessToken))
    }
  }, [instance])

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload, data) => {
      console.log(payload)
      console.log(data)
      if (
        ['MembershipActivatedEvent', 'InvitationAcceptedEvent', 'UserApprovedEvent'].includes(payload) &&
        data.UserId === loggedInUserId
      ) {
        UserService?.listOrganizations().then((response) => setOrganizations(response.organizations))
      }
      if (
        ['AppAccessDeniedToUserEvent', 'AppAccessAddedToUserEvent'].includes(payload) &&
        data.UserId === loggedInUserId
      ) {
        if (!org) return
        UserService.listApplications(org).then(setApplications)
        ProfileService.getApplications(org, loggedInUserId!).then((data) => setProfileApps(data?.applications))
      }
    },
    [],
  )

  return (
    <SignalRContext.Provider
      connectEnabled={!!token}
      accessTokenFactory={() => {
        console.log('factory token')
        const account = instance.getActiveAccount()
        if (account) {
          const accessTokenRequest = {
            scopes: ['fd1442d1-cbcc-4b73-a893-a61b8d3d4782'],
            account: account,
          }
          const newToken = instance.acquireTokenSilent(accessTokenRequest).then((response) => response.accessToken)
          console.log(newToken)
          return newToken
        }
        return token
      }}
      dependencies={[token]} //remove previous connection and create a new connection if changed
      url={`https://${getBaseUrl()}/s-notifications/api/${org}/real-time-updates`}
    >
      {/* // <ErrorHandlingInterceptor> */}
      <Layout topNavComponent={<TopNav />} sideMenuComponent={<SideNav />}>
        <DashboardSwitch />
      </Layout>
      {/* </ErrorHandlingInterceptor> */}
    </SignalRContext.Provider>
  )
}

export default Dashboard
