import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { Application, ApplicationProfile } from '../models/User'
import { useParams } from 'react-router-dom'
import UserService from '../services/UserService'
import { useMsal } from '@azure/msal-react'
import ProfileService from '../services/Profile.service'

interface ApplicationsContextType {
  applications: Application[]
  profileApps: ApplicationProfile[]
  setApplications: any
  setProfileApps: any
}

const initialContext: ApplicationsContextType = {
  applications: [],
  profileApps: [],
  setApplications: () => undefined,
  setProfileApps: () => undefined,
}

export const ApplicationsContext = createContext<ApplicationsContextType>(initialContext)

export const useApplications = () => useContext(ApplicationsContext)

const ApplicationsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { instance } = useMsal()
  const loggedInUserId = instance.getActiveAccount()?.localAccountId
  const [applications, setApplications] = useState<Application[]>([])
  const [profileApps, setProfileApps] = useState<ApplicationProfile[]>([])
  const { org } = useParams()

  useEffect(() => {
    if (!org) return
    UserService.listApplications(org).then(setApplications)
    ProfileService.getApplications(org, loggedInUserId!).then((data) => setProfileApps(data?.applications))
  }, [org, loggedInUserId])

  return (
    <ApplicationsContext.Provider value={{ applications, profileApps, setApplications, setProfileApps }}>
      {children}
    </ApplicationsContext.Provider>
  )
}

export default ApplicationsContextProvider
