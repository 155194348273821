import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { Organization } from '../models/User'
import UserService from '../services/UserService'

interface OrganizationsContextType {
  organizations: Organization[]
  setOrganizations: any
  isLoadingOrganizations: boolean
}

const initialContext: OrganizationsContextType = {
  organizations: [],
  setOrganizations: () => undefined,
  isLoadingOrganizations: true,
}

export const OrganizationsContext = createContext<OrganizationsContextType>(initialContext)

const OrganizationsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [isLoadingOrganizations, setIsLoadingOrganiztions] = useState<boolean>(true)

  useEffect(() => {
    setIsLoadingOrganiztions(true)
    UserService?.listOrganizations().then((response) => {
      setOrganizations(response.organizations)
      setIsLoadingOrganiztions(false)
    })
  }, [])
  return (
    <OrganizationsContext.Provider
      value={{ organizations, setOrganizations, isLoadingOrganizations: isLoadingOrganizations }}
    >
      {children}
    </OrganizationsContext.Provider>
  )
}

export default OrganizationsContextProvider

export const useOrganizations = () => {
  return useContext(OrganizationsContext)
}
