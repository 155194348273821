import React, { FC, useEffect, useState } from 'react'
import DataField from '../table-header/DataField'
import { Button, Input, ThrashBinIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { TableAction, TableHeaders, TableRow } from '../table/Table'
import { EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/solid'
import TableHeaderCombobox from '../table-header/TableHeaderCombobox'
import { Menu } from '@headlessui/react'
import ErrorModal from './ErrorModal'

type TableProps = {
  headers: TableHeaders[]
  rows: TableRow[]
  onHeaderFilter?: (field: string, value: any) => void
  refreshTable?: () => void
  onEdit?: (id: string) => void
  onDelete?: (id: string) => void
  onNameSearch: (value: string) => void
  filterValues: { [key: string]: any }
  onEditLinks?: (id: string) => void
  type: 'auth' | 'on-premise'
}

const Table: FC<TableProps> = ({
  headers,
  rows,
  onEdit,
  onDelete,
  onEditLinks,
  onNameSearch,
  filterValues,
  onHeaderFilter,
  type,
}) => {
  const [tableData, setTableData] = useState<TableRow[]>(rows)
  const { t } = useTranslation()
  const [pendingNameSearch, setPendingNameSearch] = useState('')
  const [errorsModalOpen, setErrorsModalOpen] = useState(false)

  useEffect(() => {
    setTableData(rows)
  }, [rows])

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      onNameSearch(pendingNameSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingNameSearch, onNameSearch])

  const menuActions = (state: string, id: string) => {
    switch (state) {
      case 'unprovisioned':
        return [
          {
            name: t('Connectors.viewErrors'),
            onClick: () => {
              setErrorsModalOpen(true)
              console.log('errors', id)
            },
          },
        ] as TableAction[]
      default:
        return [] as TableAction[]
    }
  }

  return (
    <>
      <table className='min-w-full divide-y divide-gray-200 border-collapse'>
        <thead className='text-sm bg-white'>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                data-testid='table-header'
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-normal text-slate-600 capitalize tracking-wider cursor-pointer'
              >
                <div className='inline-flex items-center justify-start gap-x-3 '>{header.label}</div>
              </th>
            ))}
          </tr>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-medium text-gray-500 min-w-[12rem] tracking-wider'
                data-testid='filter'
              >
                {header.searchType === 'search' ? (
                  <div>
                    <Input
                      id={'search'}
                      type={'search'}
                      classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100 pr-16 max-w-72'
                      placeholder={t('ServiceAccounts.search') as string}
                      value={pendingNameSearch}
                      onChange={(e) => setPendingNameSearch(e.target.value)}
                    />
                  </div>
                ) : header.searchType === 'select' ? (
                  <div>
                    <TableHeaderCombobox
                      options={
                        header.options?.map((h) => {
                          if (typeof h === 'string') {
                            return {
                              type: 'plainText',
                              value: h,
                            }
                          } else if (typeof h === 'object' && h.id) {
                            return {
                              type: 'plainText',
                              id: h.id,
                              value: h.name,
                            }
                          } else {
                            return {
                              type: 'plainText',
                              value: '',
                            }
                          }
                        }) ?? []
                      }
                      value={filterValues[header.accessor] ?? []}
                      selectFor={''}
                      onChange={(val) => onHeaderFilter && onHeaderFilter(header.accessor, val)}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-white'>
          {tableData?.map((row, index) => (
            <tr key={index} className='border-b border-gray-300 group hover:bg-sky-50' data-testid='row'>
              {headers?.map((header, index) => {
                const field = header.accessor !== 'actions' ? row[header.accessor] : null
                if (header.accessor === 'actions') {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <div className='flex items-center'>
                        <Button
                          className='!p-0 mr-3'
                          onClick={() => {
                            onEdit && onEdit(row['id']?.value)
                          }}
                        >
                          <PencilIcon className='w-4 h-4 text-gray-500 hover:text-gray-800' />
                        </Button>
                        <Button
                          className='!p-0'
                          onClick={() => {
                            onDelete && onDelete(row['id']?.value)
                          }}
                        >
                          <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                        </Button>
                        <Button
                          className='text-blue-600'
                          onClick={() => {
                            onEditLinks && onEditLinks(row['id']?.value)
                          }}
                        >
                          {t('Connectors.editLinks')}
                        </Button>
                        {type === 'on-premise' && row['state']['value'].toLocaleLowerCase() === 'unprovisioned' && (
                          <Button
                            className='text-blue-600'
                            onClick={() => {
                              console.log('provision')
                              // TO DO: implement provision
                            }}
                          >
                            {t('Connectors.provision')}
                          </Button>
                        )}
                        {type === 'auth' && (
                          <Button
                            className='text-blue-600'
                            onClick={() => {
                              console.log('test conn')
                              // TO DO: implement test conn
                            }}
                          >
                            {t('Connectors.testConnection')}
                          </Button>
                        )}
                      </div>
                    </td>
                  )
                } else {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <DataField field={field!} />
                    </td>
                  )
                }
              })}
              {type === 'on-premise' && (
                <td className='p-3'>
                  {menuActions(row['state']['value'].toLocaleLowerCase(), row['id']?.value).length > 0 && (
                    <Menu as='div' className='relative inline-block text-left'>
                      <div className=''>
                        <Menu.Button
                          className='inline-flex items-center w-full'
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <EllipsisVerticalIcon className='w-6 h-6 text-black hover:text-slate-400' />
                        </Menu.Button>
                      </div>
                      <Menu.Items className='absolute bg-white z-10 right-0 mt-1 w-56 origin-top-right rounded drop-shadow-lg shadow-lg'>
                        <div className='py-1'>
                          {menuActions(row['state']['value'].toLocaleLowerCase(), row['id']?.value)?.map(
                            (action, index) => (
                              <Menu.Item key={`action-${index}`}>
                                <button
                                  className='border-none bg-white flex items-center py-2 px-4 w-full hover:bg-sky-50'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    action.onClick(row)
                                  }}
                                >
                                  {action.name}
                                </button>
                              </Menu.Item>
                            ),
                          )}
                        </div>
                      </Menu.Items>
                    </Menu>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {errorsModalOpen && (
        <ErrorModal
          open={errorsModalOpen}
          setOpen={setErrorsModalOpen}
          // To DO: send these values dynamically
          connectorName='connector1'
          errorMsg='The on premise connector wasn’t successfully created because the server address provided was incorrect'
        />
      )}
    </>
  )
}

export default Table
