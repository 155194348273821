import React, { FC, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import { useOrganizations } from '../context/OrganizationsContext'
import ApplicationsContextProvider from '../context/ApplicationsContext'
import ErrorHandlingInterceptor from '../components/error-handling/ErrorHandlingInterceptor'
import { SpinnerIcon } from '@sistemiv/s-components'

const PrivateRoute: FC<{ children?: any }> = ({ children }) => {
  const { org } = useParams()
  const navigate = useNavigate()
  const { organizations, isLoadingOrganizations } = useOrganizations()
  useEffect(() => {
    if (isLoadingOrganizations) {
      return
    }

    if (organizations.length === 0) {
      navigate('/not-authorized')
      return
    }

    if (!org) {
      navigate(`/${organizations.at(0)?.organizationCode}`)
      return
    }

    const foundOrg = organizations.find((organization) => organization.organizationCode == org)

    if (foundOrg) {
      return
    }

    console.log('here')

    navigate('/not-authorized')
  }, [organizations, org, navigate, isLoadingOrganizations])

  const isAuthenticated = useIsAuthenticated()

  return isLoadingOrganizations ? (
    <div className='w-full flex justify-center pt-5'>
      <SpinnerIcon className='text-sky-500 h-7 w-7' />
    </div>
  ) : isAuthenticated ? (
    <ErrorHandlingInterceptor>
      <ApplicationsContextProvider>{children}</ApplicationsContextProvider>
    </ErrorHandlingInterceptor>
  ) : (
    <Outlet />
  )
}

export default PrivateRoute
